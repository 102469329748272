
.controlsSwitches{
  text-align: center;
  z-index: 43;
  padding: 20px;
  position: fixed;
  right: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 9px;
  @media only screen and (max-width: 820px) {
      padding: 0px;
  }
  &__button{
    color: lightgray;
    font-size: 40px;
    background: none;
    border: none;
    img{
      width: 55px;
    }
    @media only screen and (max-width: 820px) {
        font-size: 25px;
        img{
          width: 35px;
        }
    }
  }
  &__donations{
    color: lightgray;
    font-size: 9px;
    text-decoration: none;
  }
  &__conected_users{
    font-size: 15px;
    padding-right: 5px;
  }
  &__conected_users_icon{
    font-size: 30px;
  }
}
