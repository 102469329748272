
.cards-list {
  &__card {
    max-width: 345px;
    margin: auto;
  }
  &__media {
    height: 140px;
  }
  &__content {
    background: black;
    color: white;
    font-family: 'DegularTextDemo-Regular';
  }
  &__link {
    text-decoration: none;
  }
  &__detail {
    font-size: 11px;
    color: gray;
  }
}
