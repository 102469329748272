.infoPage{
   overflow-y: scroll;
   overflow-x: hidden;
   height: 100%;
   max-width: 100%;
   font-family: medium-content-sans-serif-font,"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif;
   font-family: 'DegularTextDemo-Regular';
   user-select: text;
  ::selection {
    background: #8a00ff;
  }
  &__header{
    /* https://i.giphy.com/media/l41lRMNcWNbthY6Wc/giphy.gif  VbzVwd1EWpavMU7f9e */
    background-image: url("/pages/rave-fronterizx.gif");
    background-size: cover;
  }
  &__text{
    text-align: justify;
    font-size: 16px;
    padding-bottom: 30px;
  }
  &__tilt{
    margin: auto;
    position: relative;
    z-index: 2;
    max-width: 800px;
    margin: auto;
    padding: 50px;
    max-height: 100vh;
  }
  &__title{
    background: black;
    font-family: 'DigestiveDemo-Three';
    font-size: calc(100% + 9vw);
    line-height: 10.1vw;
    animation: loaderFlashText .2s 0.08s infinite;
  }
  &__date{
    background: black;
    font-size: 2vw;
    font-family: 'ViktorScriptDemo-Caps';
  }
  &__text--negritas{
    animation: loaderFlashText .2s 0.08s infinite;
  }

  &__text--rave{
    animation: loaderFlash .2s 0.08s infinite;
    color: white;
  }
  &__horarios{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media only screen and (max-width: 500px) {
      display: block;
    }
    h3{
      margin: 0px;
      span {
        color: gray;
      }
    }
    ul{
      list-style-type: none;
      padding: 0px;
      li span{
        font-size: 12px;
        color: lightgray;
      }
    }
    &__column{
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }
  }
  &__logos{
    padding-top: 30px;
    display: flex;
    justifyContent: space-around;
    flexWrap: wrap;
  }
  &__logo{
    flex: 1 1 0px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    img{
      filter: invert(1);
      max-width: 100%;
      max-height: 50px;
    }
  }
}
