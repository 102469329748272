@import "../style";

.avatarSelector {
  button {
    background: none;
    border: none;
  }
  &__image {
    @include no-select;
    @include no-drag;
    width: 25vw;
    opacity: .5;
  }
  &__image-selected {
    border-radius: 15px;
  /*  background-color: #FF4000; */
    opacity: 1;
    @include bg-gradient-animation;
  }
}
