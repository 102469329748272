@import "../style";

.controls{
  @include no-select;
  @include full-size;
  @include no-drag;
  bottom: 0px;
  position: fixed;
  display: flex;
  z-index: 1;
  @media only screen and (max-width: 500px) {
      display: none;
  }
  &--hidden{
    display: none;
    border: 2px solid white;
  }
  &__container{
    @include no-select;
    @include no-drag;
    align-self: center;
    padding: 30px;
    opacity: 0.7;
    font-size: 60px;
    z-index: 50;
    color: white;
    text-align: center;
    display: inline-block;
    width: 150px;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;
    &--right{
      position: absolute;
      right: 0px;
    }
    span {
      margin: auto;
      display: flex;
    }
    div{
      height: 80px;
      display: flex;
    }
  }
  &__button{
    @include no-select;
    @include no-drag;

    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .4));
    display: inline;
    padding: 0px 10px;
    height: 30px;
    width: 30px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    img {
      height: 30px;
      width: 30px;
      pointer-events: none;
    }
    &--pressed{
      filter: brightness(50%);
    }
  }
}
