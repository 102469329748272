
.landingInfo {
  background: black;
  height: 100vh;
  max-width: 100vw;
  z-index: 100;
  position: relative;
  padding: 50px 20px;
  max-width: 800px;
  margin: auto;
  height: 100%;
}

.landingInfo--fonima {
  background-color: white;
  color: black;
  .cards-list__content {
    background-color: white;
    color: black;
  }
}
