@font-face {
    font-family: 'DigestiveDemo-Three';
    src: url('./assets/fonts/Digestive/DigestiveDemo-Three.otf');
}
@font-face {
    font-family: 'ViktorScriptDemo-Caps';
    src: url('./assets/fonts/ViktorScript/ViktorScriptDemo-Caps.otf');
}
@font-face {
    font-family: 'DegularTextDemo-Regular';
    src: url('./assets/fonts/DegularText/DegularTextDemo-Regular.otf');
}
@font-face {
    font-family: 'FKScreamerLegacyTrial-SemiBackslanted';
    src: url('./assets/fonts/FKScreamerLegacyTrial-SemiBackslanted.otf');
}
@font-face {
    font-family: 'Hanson';
    src: url('./assets/fonts/fonima/hanson/HansonBold.eot');
    src: url('./assets/fonts/fonima/hanson/HansonBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/fonima/hanson/HansonBold.woff2') format('woff2'),
        url('./assets/fonts/fonima/hanson/HansonBold.ttf') format('truetype'),
        url('./assets/fonts/fonima/hanson/HansonBold.svg#HansonBold') format('svg');
}
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/fonima/IBMPlexMono/IBMPlexMono.eot');
    src: url('./assets/fonts/fonima/IBMPlexMono/IBMPlexMono.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/fonima/IBMPlexMono/IBMPlexMono.woff2') format('woff2'),
        url('./assets/fonts/fonima/IBMPlexMono/IBMPlexMono.woff') format('woff'),
        url('./assets/fonts/fonima/IBMPlexMono/IBMPlexMono.ttf') format('truetype'),
        url('./assets/fonts/fonima/IBMPlexMono/IBMPlexMono.svg#IBMPlexMono') format('svg');
}

@mixin full-size {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin no-drag {
  -webkit-touch-drag: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}

html, body{
  @include full-size;
  padding: 0px;
  margin: 0px;
  color: #fff;
  font-family: medium-content-sans-serif-font,"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif;
  font-family: 'DegularTextDemo-Regular';
}

a {
  text-decoration: none;
  color: inherit;
}

/* avoid zoom in */
* {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

*:focus {
    outline: none;
}

@mixin styled-scroll {
  ::-webkit-scrollbar-track{
    border: 1px solid  rgba(0,0,0,.05);
    background-color:  rgba(0,0,0,.05);
  }
  ::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(0,0,0,.01);
  }
  ::-webkit-scrollbar-thumb{
    background-color: rgba(138,0,255,.4);
  }
}

canvas,
#root,
.container{
  @include no-select;
  @include no-drag;
  @include full-size;
  position: fixed;
  top: 0px;

}

.root-container {
  z-index: 101;
  max-width: 100vw;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  &__landing {
    /* fonima     */
    background-color: white;

    position: relative;
    width: 100vw;
    z-index: 100;

    &--back {
      background-color: rgba(0,0,0,0.5);
      position: fixed;
      z-index: 10;
    }

  }
}

@keyframes loaderFlashText {
    0% {
    color: #FFFFFF;
    }
    10% {
    color: #FFFFFF;
    }
    20% {
    color: #ff0000;
    }
    35% {
    color: #ff00f0;
    }
    40% {
    color: #8a00ff;
    }
    50% {
    color: #000cff;
    }
    60% {
    color: #00deff;
    }
    70% {
    color: #00ff00;
    }
    75% {
    color: #f6ff00;
    }
    80% {
    color: #ff9000;
    }
    95% {
    color: black;
    }
}

@keyframes pulsate {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes fadeout {
    from {
      top: 0;
    }
    to   {
      top: -100vh;
    }
}

/* se lo robé a Baku Hashimoto https://baku89.com/ */
@-webkit-keyframes link-bg-anim {
	from {
		background-position-x: 0
	}
	to {
		background-position-x: 1000px
	}
}

@keyframes link-bg-anim {
	from {
		background-position-x: 0
	}
	to {
		background-position-x: 1000px
	}
}

@mixin bg-gradient-animation {
  background: -webkit-gradient(linear, left top, right top, from(#2638ff), color-stop(11%, #5e25a8), color-stop(21%, #8e2ad6), color-stop(35%, #abcebf), color-stop(44%, #cbfabe), color-stop(54%, #acfc99), color-stop(63%, #7df27f), color-stop(70%, #72e0ad), color-stop(82%, #6fc9d9), to(#2638ff));
  background: linear-gradient(to right, #2638ff 0, #5e25a8 11%, #8e2ad6 21%, #abcebf 35%, #cbfabe 44%, #acfc99 54%, #7df27f 63%, #72e0ad 70%, #6fc9d9 82%, #2638ff 100%);
  background-size: 1000px 1px;
  background-attachment: fixed;
  background-position: 0 0;
  -webkit-animation-name: link-bg-anim;
  animation-name: link-bg-anim;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.channelSwitchButton{
  @include no-select;
  z-index: 400;
  position: absolute;
  font-size: 8px;
  background: slateblue;
  border-radius: 10px;
  right: 5px;
  color: white;
  top: 5px;
}

@keyframes main-logo-animaion{
    0% {
        fill: #000cff;
    }
    50% {
        fill: #ff9000;
    }
    100% {
        fill: #8a00ff;
    }
}

.main-logo{
  position: fixed;
  padding: 0px;
  z-index: 10;

  &__svg{
    @include no-select;
    user-select: none;
  position: absolute;
  width: 12vw;
  padding: 20px;
    g {
     fill: #FFFFF;
    }
    path {
     fill: transparent;
     animation: main-logo-animaion 1s infinite;
     stroke: #e5e5e5;
     stroke-width: 0px;
    }
  }
  &__png{
    @include no-select;
    user-select: none;
    position: absolute;
    width: 12vw;
    padding: 20px;
    opacity: 0.4;
  }
}

@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
