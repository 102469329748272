.infoPage{
   overflow-y: scroll;
   overflow-x: hidden;
   height: 100%;
   max-width: 100%;
   font-family: medium-content-sans-serif-font,"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif;
   font-family: 'DegularTextDemo-Regular';
   user-select: text;
   background: #F3F3F3;
   color: black;
  ::selection {
    background: #A792FF;
  }
  ul {
    li {
      padding: 20px;
    }
    img {
      max-width: 100%;
    }
  }
  h1 {
    font-family: 'FKScreamerLegacyTrial-SemiBackslanted';
    font-size: 70px;
    margin: 0px;
  }
  &__header{
    background-image: url("../../assets/pages/fonima/background-fonima.png");
    animation: fonimaFlashText 2s 0.08s infinite;
    background-size: cover;
  }
  &__text{
    text-align: justify;
    font-size: 16px;
    padding-bottom: 30px;
  }
  &__tilt{
    margin: auto;
    position: relative;
    z-index: 2;
    max-width: 800px;
    margin: auto;
    padding: 50px;
    max-height: 100vh;
    text-align: center;
  }
  &__title{
    background: black;
    font-family: 'DigestiveDemo-Three';
    font-size: calc(100% + 9vw);
    line-height: 10.1vw;
    animation: fonimaFlashText .2s 0.08s infinite;
  }
  &__date{
    background: white;
    font-size: 2vw;
    font-family: 'ViktorScriptDemo-Caps';
  }
  &__text--negritas{
    animation: fonimaFlashText 2s 0.08s infinite;
  }

  &__text--rave{
    animation: loaderFlash .2s 0.08s infinite;
    color: white;
  }
  &__horarios{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media only screen and (max-width: 500px) {
      display: block;
    }
    h3{
      margin: 0px;
      span {
        color: gray;
      }
    }
    ul{
      list-style-type: none;
      padding: 0px;
      li span{
        font-size: 12px;
        color: gray;
      }
    }
    &__column{
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }
  }
  &__logos{
    padding-top: 30px;
    display: flex;
    justifyContent: space-around;
    flexWrap: wrap;
  }
  &__logo{
    flex: 1 1 0px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    img{
      filter: invert(1);
      max-width: 100%;
    }
  }
}


.grid{
  &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 1px 0 0;
  }

  &__item {
      flex: 0 1 33.333333%;
      margin: 0 0 1px;
      height: 150px;
      @media only screen and (max-width: 800px) {
        flex: 0 1 33.333333%;
      }
      @media only screen and (max-width: 700px) {
        flex: 0 1 50%;
      }
      @media only screen and (max-width: 600px) {
        flex: 0 1 100%;
      }
  }

  &__item-outline {
      margin: 0 0 0 1px;
      outline: 1px solid black;
      height: 150px;
  }
  &__item-picture {
    height: 150px;
    background-position: center top;
    background-size: cover;
  }
  &__item-header {
      border-bottom: 1px solid black;
      span {
        border-right: 1px solid black;
        padding: 0px 5px;
      }
  }
}
