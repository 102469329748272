

.react-swipeable-view-container{
  position: absolute;
  width: 100vw;
}

.instructionsSlider{
  &__container{
    overflow: hidden;
    display: flex;
    width: 100vw;
    height: 100vh;
  }
  &__title{
    font-family: 'Hanson';
    font-size: 3vw;
  }
  &__content{
    font-family: 'IBM Plex Mono';
    background-color: white;
    color: black;
    margin: 20vw;
    width: 100vw;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 5%;
    flex-wrap: wrap;
    text-align: center;
    div {
      width: 100%;
    }
    svg{
      margin: 20px;
    }
  }
  &__nextButton{
    font-family: 'IBM Plex Mono';
    color: black;
    border: 1px solid black;
    background-color: rgb(186, 247, 199);
    padding: 10px;
  }
  &__startButton{
    font-family: 'Hanson';
    background: none;
    border: none;
    font-weight: 800;
    color: transparent;
    font-size: 5vw;
    -webkit-text-stroke: 1px black;
  }
}
