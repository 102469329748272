@import "../../style";

.login{
  @include no-select;
  @include full-size;
  position: relative;
  z-index: 44;
  background: black;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  > div {
    width: 100%; /* fixes display flex size in ff */
  }
  &--hide{
    -webkit-animation: fadeout 3s;
       -moz-animation: fadeout 3s;
        -ms-animation: fadeout 3s;
         -o-animation: fadeout 3s;
            animation: fadeout 3s;
    top: -100vh;
  }
  &__logoBg{
    @include no-drag;
    @include bg-gradient-animation;
    margin: auto;
    max-width: 40%;
    border: none;
    box-shadow: inset 0 0 0 2px black;
    padding: none;
    text-align: center;
    @media only screen and (max-width: 500px) {
      max-width: 70%;
    }
  }
  &__logo{
    display: block;
    width: 101%;
    g {
     fill: #FFFFF;
    }
    path {
     fill: black;
    }
  }
  &__name-input{
    text-align: center;
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
    color: white;
    font-size: 20px;
    outline: none;
    width: 30vw;
    @media only screen and (max-width: 500px) {
      width: 60vw;
      font-size: 15px;
    }
  }
  &__button{
    background: none;
    border: none;
    border-radius: 30px;
    outline: none;
    font-weight: 800;
    color: transparent;
    font-size: 5vw;
    -webkit-text-stroke: 1px white;
    &--pulsate{
        animation: pulsate 3s ease-out;
        animation-iteration-count: infinite;
        opacity: 0.5;
    }
  }
}

/*
fonima colors
background: rgb(167, 146, 255);
background: rgb(186, 247, 199);
background: rgb(255, 181, 146);
*/

.login--fonima {
  
  color: black;
  background-color: white;
  .avatarSelector__image {
    opacity: 1;
  }
  .avatarSelector__image-selected {
    background: rgb(186, 247, 199);
    animation: none;
    border-radius: 50%;
    padding: 5%;
  }
  .login__logoBg {
    filter: invert(1);
  }
  .login__name-input {
    color: black;
    input {
      text-align: center;
    }
    .MuiInput-underline:after {
      border-bottom: 2px solid black;
    }
  }
  .login__button{
    -webkit-text-stroke: 1px black;
  }

  .scrollDownButton__link span{
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }

}
