
.scrollDownButton {
  position: relative;
  margin-top: 10px;
  /*
  bottom: 150px;*/
  width: 100vw;
  &__link {
    padding-top: 80px;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 12px;
      height: 12px;
      margin-left: -6px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: sdb07 2s infinite;
      animation: sdb07 2s infinite;
      opacity: 0;
      box-sizing: border-box;
    }
    span:nth-of-type(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    span:nth-of-type(2) {
      top: 16px;
      -webkit-animation-delay: .15s;
      animation-delay: .15s;
    }
    span:nth-of-type(3) {
      top: 32px;
      -webkit-animation-delay: .3s;
      animation-delay: .3s;
    }
  }
}
